<template>
  <div class="searchDetail flexmode">
    <div
      class="swrapper flexmode"
      v-for="(item, index) in searchdata"
      :key="index"
    >
      <div class="haveicon point" @click="gosearchdetail(item)">
        <a-avatar :size="64" :src="item.vupFace">
          <!-- <template #icon><UserOutlined /></template> -->
        </a-avatar>
        <div class="userinfo point">
          <div class="infoname">{{ item.vupName }}</div>
          <div class="infotitle">{{ item.vupSign }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive, toRefs } from "vue";
import { useRoute } from "vue-router";
import { findVupName } from "../../api/vup/vup";
export default {
  setup() {
    const route = useRoute();
    // const router = useRouter();
    const formState = reactive({
      searchdata: [],
    });
    onMounted(() => {
      findVupNames();
    });
    async function findVupNames() {
      let { code, data } = await findVupName({
        name: route.query.name,
      });
      if (code === 200) {
        // console.log(data);
        formState.searchdata = data;
      }
    }
    function gosearchdetail(item) {
      localStorage.setItem("vupdetail", JSON.stringify(item));
      // router.push({ path: "/vup/vupdetail", query: { detailid: item.vupid } });
    }
    return {
      ...toRefs(formState),
      gosearchdetail,
    };
  },
};
</script>
<style lang="scss" scoped>
.searchDetail {
  flex-wrap: wrap;
  .swrapper {
    width: 240px;
    margin-right: 10px;
    margin-top: 10px;

    .haveicon {
      width: 100%;
      height: 290px;
      background: #99cc99;
      text-align: center;
      padding-top: 50px;
      border-radius: 10px;
      .icon {
        text-align: center;
        span {
          display: inline-block;
          width: 100px;
          height: 100px;
          border-radius: 50%;
          background: white;
        }
      }
      .userinfo {
        color: white;
      }
    }
  }
}
</style>
